import React from "react";
import "./about.css";
import ME from "../../assets/photo1 dark.png";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { BsFolderCheck } from "react-icons/bs";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about_container">
        <div className="about_me">
          <div className="about_me-image">
            <img src={ME} alt="Aboutpic" />
          </div>
        </div>
        <div className="about_content">
          <div className="about_cards">
            <article id="about_card" className="about_card">
              <span>
                <FaAward className="about_icon" />
                <h5>Experience</h5>
                <small>3+ Years Web Developer</small>
              </span>
            </article>

            <article id="about_card" className="about_card">
              <span>
                <FiUsers className="about_icon" />
                <h5>Clients</h5>
                <small>5+</small>
              </span>
            </article>
            <article className="about_card">
              <span>
                <BsFolderCheck className="about_icon" />
                <a href="#portofolio">
                  {" "}
                  <h5>Projects</h5>
                  <small>15+ Completed</small>
                </a>
              </span>
            </article>
          </div>
          <p>
            Hello! I’m Konsatninos Tzallas, a passionate web developer with a strong
            foundation in modern web technologies and a drive to create
            impactful, user-friendly digital experiences. I specialize in
            Angular, HTML5, CSS3, and Javascript, and have experience
            collaborating on open-source projects that showcase my commitment to
            community-driven development. My skillset also includes working with
            SQL databases,  RESTful APIs, and understanding Python for
            backend functionality. I thrive on continuous learning and
            problem-solving, and I’m excited to contribute to innovative teams
            where I can grow and make a difference. Based in Corinthos,
            I’m seeking remote opportunities in Greece or Europe that value
            creativity, collaboration, and technical excellence. Let’s build
            something amazing together!
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
